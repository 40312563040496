<template>
  <div>
    <portal to="header">
      <terminal-header
        :title="$t('Measuring of items')"
        :out-focused="isFocused"
        @barcode="handleBarcode"
      />
    </portal>

    <q-carousel
      v-model="slide"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-min-height"
    >
      <q-carousel-slide name="scan" class="q-pa-none">
        <div class="relative q-py-sm fit">
          <div class="q-py-sm text-center text-subtitle1 border-bottom">
            {{ $t('Scan item/place/sizes') }}
          </div>

          <q-slide-transition>
            <div
              v-if="isOpenMeasuring"
              class="q-px-sm"
            >
              <div
                v-if="item"
                class="q-mb-md"
              >
                <order-item
                  class="place-item"
                  :item="item"
                />
              </div>

              <form @submit="handleDimensionsSubmit">
                <div class="row items-center q-mb-md">
                  <div class="col-12 col-md-3 q-mb-sm">
                    <q-input
                      v-model="weight"
                      standout="bg-teal text-white"
                      type="number"
                      class="q-mx-md"
                      :label="$t('Weight')"
                      dense
                      @focus="onFocus"
                      @blur="onFocusOut"
                    />
                  </div>

                  <div class="col-12 col-md-3 q-mb-sm">
                    <q-input
                      v-model="width"
                      standout="bg-teal text-white"
                      type="number"
                      class="q-mx-md"
                      :label="$t('Width')"
                      dense
                      @focus="onFocus"
                      @blur="onFocusOut"
                    />
                  </div>

                  <div class="col-12 col-md-3 q-mb-sm">
                    <q-input
                      v-model="length"
                      standout="bg-teal text-white"
                      type="number"
                      class="q-mx-md"
                      :label="$t('Length')"
                      dense
                      @focus="onFocus"
                      @blur="onFocusOut"
                    />
                  </div>

                  <div class="col-12 col-md-3 q-mb-sm">
                    <q-input
                      v-model="height"
                      standout="bg-teal text-white"
                      type="number"
                      class="q-mx-md"
                      :label="$t('Height')"
                      dense
                      @focus="onFocus"
                      @blur="onFocusOut"
                    />
                  </div>
                </div>

                <div class="q-py-sm text-center">
                  <q-btn
                    color="light-blue-9"
                    text-color="white"
                    :label="$t('Submit')"
                    type="submit"
                    no-caps
                    unelevated
                    @click="handleDimensionsSubmit"
                  />
                </div>
              </form>
            </div>
          </q-slide-transition>
        </div>
      </q-carousel-slide>

      <q-carousel-slide name="allItems" class="q-pa-none">
        <div class="relative q-pa-xs fit">
          <div class="q-py-sm text-center text-subtitle1 border-bottom q-mb-xs">
            {{ $t('All items') }}
          </div>

          <div class="row">
            <div
              v-for="item in items"
              :key="item.id"
              class="col-12 col-md-6 q-pa-xs"
              @click="handleItem(item)"
            >
              <order-item
                class="place-item"
                :item="item"
              />
            </div>
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'Measuring',
  data () {
    return {
      slide: 'scan',
      items: [],
      item: null,
      isOpenMeasuring: false,
      weight: null,
      width: null,
      height: null,
      length: null,
      isFocused: false
    }
  },
  mounted () {
    const query = {
      action: 'get-item-measurements',
      criteria: {
        warehouse: this.$route.params.id
      }
    }

    this.loadItems(query)
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'addNotification'
    ]),
    handleDimensionsSubmit (e) {
      if (e) {
        e.preventDefault()
      }

      if (!this.weight || !this.width || !this.height || !this.length) {
        return
      }

      const data = {
        action: 'add-measurements',
        dimensions: {
          x: Number(this.width),
          y: Number(this.length),
          z: Number(this.height)
        },
        weight: Number(this.weight)
      }

      const query = {
        criteria: {
          sku: this.item.sku
        }
      }

      return this.$service.storageItem.patch(this.$route.params.id, data, query)
        .then(data => {
          this.addNotification('Successful measered!')
          this.item = null
          this.width = null
          this.length = null
          this.height = null
          this.isOpenMeasuring = false
        })
    },
    onFocus () {
      this.isFocused = true
    },
    onFocusOut () {
      this.isFocused = false
    },
    handleItem (item) {
      this.slide = 'scan'
      this.item = item
      this.isOpenMeasuring = true
    },
    loadItems (query) {
      return this.$service.storageItem.getAll(query)
        .then(data => {
          this.items = data.items
          return data
        })
    },
    handleBarcode (barcode) {
      const actions = {
        'S/P': barcode => {
          const query = {
            action: 'get-item-measurements',
            criteria: {
              warehouse: this.$route.params.id,
              place: barcode.value
            }
          }

          return this.loadItems(query)
            .then(data => {
              this.items = data.items
            })
        },
        X: barcode => {
          this.width = barcode.value
        },
        Y: barcode => {
          this.height = barcode.value
        },
        Z: barcode => {
          this.length = barcode.value
        },
        DB: barcode => {
          this.weight = barcode.value[0]
          this.width = barcode.value[1]
          this.height = barcode.value[2]
          this.length = barcode.value[3]
        }
      }

      if (actions[barcode.type]) {
        return actions[barcode.type](barcode)
      }

      const item = this.items.find(i => i.sku === barcode.raw)

      if (!item) {
        this.addErrorNotification('Item is not found')
        return
      }

      this.handleItem(item)
    }
  }
}
</script>

<style>
.place-item {
  cursor: pointer;
  transition: box-shadow .3s;
}

.place-item:hover {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
</style>
